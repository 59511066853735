<template>
	<div class="modal order-modal">
    <div 
      class="overlay" 
      @click="$emit('closeOrderModal')"
    />
    <div class="wrapper">
      <div class="container">
        <img 
          src="./../assets/img/close.svg"
          @click="$emit('closeOrderModal')"
          class="close"
        />
        <div class="title small">{{$t("Payment Method")}}</div>
        <div class="button-list-container">
          <div class="button-list" v-if="paymentMethods && paymentMethods.length">
            <button v-for="(item, i) in paymentMethods" class="img-btn" @click="submit(item.code)" :key="i">
              <img :src="appDomain + item.image" class="img"/>
              {{ item.title }}
            </button>
          </div>
          <Transition>
            <div class="desc red" v-if="error">{{error}}</div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
	name: 'OrderModal',
	props: {
    paymentMethods: {
			type: Array,
			required: true
		},
    error: {
			type: String,
			required: true
		},
    orderData: {
			type: Object,
			required: true
		}
    
  },
	data: function() {
		return {
      appDomain: ''
		}
	},
	methods: {
		submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
	},
	mounted() {
    this.appDomain = process.env.VUE_APP_DOMAIN;
  }
}
</script>
