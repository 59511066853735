<template>
  <div class="item-wrapper">
    <div class="item-top">
      <img v-if="sale" src="./../assets/img/flame.svg" class="flame">
      <div v-if="hasActiveDiscount" class="discount-badge">
        <img src="./../assets/img/discount-icon.svg" alt="Discount" class="discount-icon">
        <!-- <span class="discount-text">-{{ discount }}%</span> -->
      </div>
      <div class="preview" @click="goToProduct(item)">
        <img :src="getImageUrl(item)" class="img" :alt="getTitle(item)">
      </div>
    </div>
    <div class="item-bottom">
      <div class="title-container" @click="goToProduct(item)">
        <div class="desc">
          <b>{{ getTitle(item) }}</b>
        </div>
      </div>
      <div class="price-container" :class="{ 'has-discount': hasActiveDiscount }">
        <template v-if="hasActiveDiscount">
          <div class="old-price">{{ item.old_price }} {{ currencySymbol }}</div>
          <div class="price desc discounted">
            <span>
              <b>{{ item.price }} {{ currencySymbol }}</b>
            </span>
          </div>
        </template>
        <template v-else>
          <div class="price desc blue">
            <span>
              <b>{{ item.price }}</b>
              <span class="currency">
                <b>{{ currencySymbol }}</b>
              </span>
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductCard',
  props: {
    item: {
      type: Object,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    },
    sale: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      imgDomain: ''
    }
  },
  computed: {
    ...mapGetters('discount', ['hasActiveDiscount']),
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToProduct(item) {
      this.$emit('goToProduct', item);
    },
    getTitle(item) {
      return item.title || item.name;
    },
    getImageUrl(item) {
      if (!item || !item.img_url || !item.item_type) {
        return '';
      }
      return item.item_type === 'pack' ? item.img_url : this.imgDomain + item.img_url;
    },
  }
}
</script>

<style lang="scss" scoped>
.item-wrapper {

  .discount-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    // background-color: rgba(0, 0, 0, 0.7);
    border-radius: 12px;
    // padding: 4px 8px;
    z-index: 1;
  }

  .discount-icon {
    width: 24px;
    height: 24px;
    pointer-events: none;
    // margin-right: 4px;
  }

  // .discount-text {
  //   color: white;
  //   font-family: 'Quantico', sans-serif;
  //   font-size: 12px;
  //   font-weight: 700;
  // }

  .price-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    height: 26px;
  }

  .price-container.has-discount {
    gap: 8px;
    align-items: center;
  }

  .old-price {
    font-family: 'Quantico', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color: white;
    text-decoration: line-through;
  }

  .price.discounted {
    font-family: 'Quantico', sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    margin-top: 0;
  }

  .price.blue {
    color: #6D82F2;
  }

  .price.discounted {
    color: #E63333;
  }
}
</style>