<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignIn')" />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" src="./../assets/img/close.svg" @click="$emit('closeSignIn')" />
            <div class="modal-main">
              <div :class="[
      'form-fields login-content',
      { hidden: !loginContentVisible },
    ]">
                <div class="form-fields-wrapper">
                  <div class="title small">
                    {{ $t("Sign In") }}
                  </div>
                  <label>
                    <input v-model="email" type="email" placeholder="Email*" />
                  </label>
                  <label>
                    <input v-model="pass" type="password" placeholder="Password" />
                  </label>
                  <div class="link-container">
                    <a class="desc link" @click="switchToforgotPass">
                      {{ $t("Forgot password?") }}
                    </a>
                  </div>
                  <div class="cta-container">
                    <button class="button blue" @click="submitLogin">
                      <div v-if="loaderIsVisible" class="loader-container">
                        <div class="loader"></div>
                      </div>
                      <span v-if="!loaderIsVisible">{{ $t("Sign In") }}</span>
                    </button>
                  </div>
                  <Transition>
                    <div v-if="error" class="error-desc desc red">
                      {{ error }}
                    </div>
                  </Transition>
                  <Transition>
                    <div v-if="showResendOption" class="resend-option">
                      <button class="resend-button button blue" @click="resendVerificationEmail">
                        {{ $t("Resend Verification Email") }}
                      </button>
                    </div>
                  </Transition>
                  <div class="modal-bottom">
                    <div class="desc">
                      {{ $t("Don't have an account?") }}
                    </div>
                    <a class="desc link" @click="openSignUp()">
                      {{ $t("Sign up") }}
                    </a>
                  </div>
                </div>
              </div>
              <div :class="[
      'form-fields forgot-pass-content',
      { hidden: !forgotPassContentVisible },
    ]">
                <div class="form-fields-wrapper">
                  <div class="title small">
                    <b>{{ $t("Forgot password?") }}</b>
                  </div>
                  <label>
                    <input v-model="email" type="email" placeholder="Email" autocomplete="username" />
                  </label>
                  <div class="cta-container">
                    <button class="button blue" @click="submitForgotPass">
                      <div v-if="loaderIsVisible" class="loader-container">
                        <div class="loader"></div>
                      </div>
                      <span v-if="!loaderIsVisible">{{ $t("Recover") }}</span>
                    </button>
                  </div>
                  <Transition>
                    <div v-if="successMessage" class="desc green">
                      {{ successMessage }}
                    </div>
                  </Transition>
                  <Transition>
                    <div v-if="error" class="error-desc desc red">
                      {{ error }}
                    </div>
                  </Transition>
                  <div class="modal-bottom">
                    <div class="desc">
                      {{ $t("Have account?") }}
                    </div>
                    <a class="desc link switch-login" @click="switchToLogin()">
                      {{ $t("Login") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginModal",
  props: {
    error: {
      type: String,
      required: true
    },
    successMessage: {
      type: String,
      required: true
    },
    loaderIsVisible: {
      type: Boolean,
      required: true
    },
    showResendOption: {
      type: Boolean,
      required: true
    },
  },
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: "",
      pass: "",
    };
  },
  mounted() {
   },
  methods: {
    resendVerificationEmail() {
      this.$emit('resendVerificationEmail', this.email);
    },
    openSignUp() {
      this.$emit("clearError");
      this.$emit('openSignUp');
    },
    switchToforgotPass() {
      this.$emit('hideResendOption');
      this.$emit("clearError");
      setTimeout(() => {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100);
    },
    switchToLogin() {
      this.$emit('hideResendOption');
      this.$emit("clearError");
      setTimeout(() => {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100);
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass,
      };
      this.$emit("login", loginData);
    },
    submitForgotPass() {
      let data = {
        email: this.email,
      };
      this.$emit("forgotPass", data);
    },
  },
};
</script>

<style scoped lang="scss">
.resend-button {
  width: 100%;
  margin-top: 16px;
}
</style>